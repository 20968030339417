import { Location } from 'history';
import { ReactNode, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { UnsavedChangesModal } from '../UnsavedChangesModal';

/**
 * Reusable component to show a modal when trying to navigate away from the current page
 * Useful for cases where there's an important action we need users to make; e.g. unsaved changes in document
 * How this works:
 * - React router v4+ has a Prompt component that does all under-the-hood prevention
 * - Prompt accepts two props: when and message. When is the condition to show, message is the description set
 * - Downside of using Prompt on its own is that it uses the Browser Prompt which doesn't give us any room for localisation
 * - Good news is that the message prop can either be a string or a function; if it returns true it shows the Prompt, false it doesn't
 * - The catch is that we can still stop navigation with the when prop and also pass in false for message so it doesn't use the Browser prompt
 * - Below makes use of this workaround and shows our own custom modal instead
 *
 * * I initially housed all the handlers inside here so we only have to pass in a condition to show the modal
 * Decided against it in the end as it goes against our philosophy of having smart reusable components.
 * Instead a callback function of the intended result when confirming to leave is also passed in
 * Required props:
 * @isPreventing - boolean to whether or not show the modal when navigating away
 * @onConfirm - function for handling when a user confirms to navigate away; has a nextLocation argument which has the intended Location data
 * More in-depth reading: https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 */
interface Props {
  isPreventing: boolean;
  onConfirm: (nextLocation: Location) => void;
  children?: ReactNode;
  secondary?: string;
  testId?: string;
  title?: string;
  useModal?: boolean;
}
export const PreventNavigationModal = ({
  isPreventing,
  children = 'Some information you’ve entered might not be saved.',
  secondary = 'Navigate away',
  title = 'Navigate away?',
  testId = 'PreventNavigationModal',
  onConfirm,
  useModal = true,
}: Props) => {
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState<boolean>(false);
  const [locationToNavigate, setLocationToNavigate] = useState<Location | null>(null);
  const [isConfirmNavigation, setIsConfirmNavigation] = useState<boolean>(false);

  const handlePreventNavigation = (nextLocation: Location): boolean => {
    if (!isConfirmNavigation) {
      setIsLeaveModalOpen(true);
      setLocationToNavigate(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigation = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmNavigation(true);
  };

  //In a useEffect instead of inside the confirm navigation handler as the event loop for Prompt needs to run and allow navigation first
  useEffect(() => {
    if (isConfirmNavigation && locationToNavigate) {
      onConfirm(locationToNavigate);
    }
  }, [isConfirmNavigation, locationToNavigate]);

  return (
    <>
      <Prompt message={handlePreventNavigation} when={isPreventing} />
      {useModal && (
        <UnsavedChangesModal
          isOpen={isLeaveModalOpen}
          onCancel={() => setIsLeaveModalOpen(false)}
          onClose={() => setIsLeaveModalOpen(false)}
          onSecondary={handleConfirmNavigation}
          secondary={secondary}
          testId={testId}
          title={title}
        >
          {children}
        </UnsavedChangesModal>
      )}
    </>
  );
};
