import { fetchEventSource } from '@microsoft/fetch-event-source';
import { get } from 'lodash';
import { camelcaseKeys, snakecaseKeys } from 'utils/formatting';
import { fetch } from 'whatwg-fetch';
import { EngageAPIError } from '../app/schemas/error';
import { ENDPOINT, V2_ENDPOINT } from '../config/constants';

interface FetchParams {
  body?: string;
  credentials?: string;
  headers?: object;
  method?: string;
  signal?: AbortSignal; // Abort one or more async operations
}

type VersionType = 'v1' | 'v2';

const base = ENDPOINT; //here for convenience, will refactor in the future
const v2base = V2_ENDPOINT;
const CREDENTIALS = 'include';

export async function parseJSON(response: Response): Promise<object> {
  const reloadErrors = [401];
  if (reloadErrors.includes(response.status)) {
    //If there's an auth error we clear the session and reload
    localStorage.removeItem('auth/data');
    window.location.reload();
    return {};
  }

  let data;
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.toLowerCase().includes('application/json')) {
    data = await response.json();
    data = camelcaseKeys(data, { deep: true });
  } else {
    data = await response.text();
  }
  if (!response.ok) {
    if (!data?.errors) {
      data = { errors: [{ detail: 'Oops something went wrong!' }] };
    }
    throw new EngageAPIError(response.status, data);
  }
  return data;
}

const requestHelper = ({ request, token }: { request: FetchParams; token?: string }) => {
  const authData = localStorage.getItem('auth/data');

  if (authData && !token) {
    const d = JSON.parse(authData);
    token = get(d, 'session.jwt');
  }

  const updatedRequest = {
    headers: {
      accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : null,
      ...(request.headers || {}),
    },
    credentials: CREDENTIALS,
    ...request,
  };

  return updatedRequest;
};
export async function request(
  url: string,
  request: FetchParams,
  version?: VersionType,
  token?: string
): Promise<object> {
  const requestToSend = requestHelper({ request, token });
  const baseToUse = version === 'v2' ? v2base : base;

  return fetch(`${baseToUse}${url}`, requestToSend).then(parseJSON);
}
export function getJSON(url: string, version?: VersionType): Promise<any> {
  return request(url, { method: 'GET' }, version);
}

export function post(
  url: string,
  body = {},
  attributes?: {
    signal?: AbortSignal;
    token?: string;
    version?: VersionType;
  }
): Promise<any> {
  const { signal, token, version } = attributes || {};
  return request(
    url,
    {
      method: 'POST',
      body: JSON.stringify(snakecaseKeys(body, { deep: true })),
      signal,
    },
    version,
    token
  );
}
export function patch(url: string, body = {}): Promise<any> {
  return request(url, {
    method: 'PATCH',
    body: JSON.stringify(snakecaseKeys(body, { deep: true })),
  });
}
export function put(url: string, body = {}, version?: VersionType): Promise<any> {
  return request(
    url,
    {
      method: 'PUT',
      body: JSON.stringify(snakecaseKeys(body, { deep: true })),
    },
    version
  );
}
export function destroy(url: string, body = {}): Promise<any> {
  return request(url, {
    method: 'DELETE',
    body: JSON.stringify(snakecaseKeys(body, { deep: true })),
  });
}
export async function fileRequest(
  url: string,
  request: FetchParams = {},
  token?: string
): Promise<object> {
  const authData = localStorage.getItem('auth/data');

  if (authData && !token) {
    const d = JSON.parse(authData);
    token = get(d, 'session.jwt');
  }

  request.headers = {
    accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : null,
    ...(request.headers || {}),
  };
  request.credentials = CREDENTIALS;

  return fetch(`${base}${url}`, request).then(async (r: Response) => ({
    blob: await r.blob(),
    headers: r.headers,
  }));
}
export async function fileUpload(url: string, attributes: any, token?: string): Promise<any> {
  // Converts data to multipart/form-data format
  const _attributes: any = snakecaseKeys(attributes, { deep: false });
  const data = new FormData();
  (Object.keys(_attributes) as Array<keyof any>).forEach((key: any) => {
    data.append(key, _attributes[key]);
  });
  const authData = localStorage.getItem('auth/data');

  if (authData && !token) {
    const d = JSON.parse(authData);
    token = get(d, 'session.jwt');
  }
  const request: {
    body: FormData;
    credentials: string;
    headers: object;
    method: string;
  } = {
    method: 'POST',
    body: data,
    headers: {
      accept: '*/*',
      Authorization: token ? `Bearer ${token}` : null,
    },
    credentials: CREDENTIALS,
  };

  return fetch(`${base}${url}`, request).then(parseJSON);
}
export interface StreamDataProps {
  method: string;
  onSuccess: (response: any) => void;
  payload: any;
  signal: AbortSignal | null;
  url: string;
  onClose?: () => void;
  onError?: (errors: EngageAPIError | any) => void;
  onOpen?: () => void;
  onSettled?: (response: any) => void;
}
/**
 * Helper to handle requests for Server-Sent Events endpoints
 * This uses fetch-event-source which takes advantage of the Fetch and EventSource APIs
 * Normally EventSource requests are only GETS but this library extends it so we can use any method
 *
 * Error handling is tricky as we cannot be too sure that an error would be in the form of an EngageAPIError
 * Assuming so will result in subsequent errors where handlers will fail if the error isn't an instance of it
 * Instead of forcing it to be an EngageAPIError, we'll pass the error either that or any and leaving the handlers to figure it out themselves
 */
export async function streamData<T>({
  url,
  method,
  payload,
  signal,
  onSuccess,
  onClose,
  onError,
  onOpen,
  onSettled,
}: StreamDataProps) {
  const authData = localStorage.getItem('auth/data');
  let token: string = '';
  if (authData) {
    const d = JSON.parse(authData);
    token = get(d, 'session.jwt');
  }

  let response: any;
  try {
    await fetchEventSource(`${ENDPOINT}${url}`, {
      signal,
      method,
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      credentials: CREDENTIALS,
      openWhenHidden: true,
      async onopen(response) {
        onOpen && onOpen();
        if (!response.ok) {
          //Throws an error when failing to connect
          //We need to convert the response into json since it's default a ReadableStream
          const errorResponse = await response.json();
          if (errorResponse.errors) {
            throw new EngageAPIError(response.status, errorResponse);
          } else {
            throw new Error('An error occured');
          }
        }
      },
      onmessage(ev) {
        const data = camelcaseKeys(JSON.parse(ev.data), { deep: true });
        /**
         * BE is unable to pass an error code into an EventStream
         * Aside from openAI errors, we only really want to display the error messages anyway
         * 503: Special error status to determine if it originated from openAI
         * 500: Passing a generic error status for every other error
         **/
        if (data.errors) {
          if (data.errors[0].detail.includes('[OpenAI]')) {
            throw new EngageAPIError(503, data);
          } else {
            throw new EngageAPIError(500, data);
          }
        }
        response = data;
        onSuccess(data);
      },
      onclose() {
        onClose && onClose();
        onSettled && onSettled(response);
      },
      onerror(err) {
        throw err; // rethrow to stop the operation; will retry connection otherwise
      },
    });
  } catch (error) {
    onError && onError(error);
  }
  return response as T;
}
