import { IconProps } from '@saleswhale/barnacle/icons/types';
import { ReactElement } from 'react';
import { BadgeColor, Badge } from '../Badge';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { AccordionCard } from './AccordionCard';
import { AccordionRing } from './AccordionRing';
import styles from './Accordion.module.scss';
import './Accordion.scss';

interface AccordionProps {
  isActive: boolean;
  title: string | ReactElement;
  toggleAccordion: () => void;
  badgeIcon?: {
    color: BadgeColor;
    icon: React.FC<IconProps>;
  } | null;
  bodyClassName?: string;
  buttonDisabled?: boolean;
  buttonLabel?: string;
  children?: React.ReactNode;
  currentStep?: number;
  disabled?: boolean;
  goToNextStep?: () => void;
  goToPrevStep?: () => void;
  isAlwaysActive?: boolean;
  isCompletingStep?: boolean;
  isLoading?: boolean;
  required?: boolean;
  status?: string;
  statusColor?: BadgeColor;
  stepDetail?: boolean;
  stepIcon?: ReactElement;
  stepsToGo?: number;
  subtitle?: string;
  testId?: string;
  totalSteps?: number;
  variant?: string;
}

// TODO: add logic to expand the accordion when the content expands.
// TODO: Add logic to support different classes of accordions
export const Accordion = ({
  variant,
  stepIcon,
  stepDetail,
  testId = 'Accordion',
  title,
  subtitle,
  required,
  children,
  isActive,
  isLoading,
  disabled = false,
  status,
  statusColor,
  totalSteps,
  currentStep = 1,
  stepsToGo,
  buttonLabel = 'Next',
  buttonDisabled,
  toggleAccordion,
  goToNextStep,
  goToPrevStep,
  isAlwaysActive = false,
  isCompletingStep = false,
  bodyClassName = '',
  badgeIcon,
}: AccordionProps) => {
  const detail = () => {
    if (stepDetail) {
      if (stepsToGo) {
        return (
          <span className="Accordion__detail" data-testid="Accordion__stepCount">
            {stepsToGo} more {stepsToGo > 1 ? 'steps' : 'step'}
          </span>
        );
      } else {
        return (
          <span
            className="Accordion__detail Accordion__detail--done"
            data-testid="Accordion__stepComplete"
          >
            All done!
          </span>
        );
      }
    }
    return '';
  };

  const header = (
    <div className="Accordion__header">
      <div>
        {stepIcon && <div className="Accordion__headerLeftIcon">{stepIcon}</div>}
        <div className="Accordion__titleFrame">
          <div>
            <div className="Accordion__title">{title}</div>
            {required ? (
              <Badge color="primary" label="Required" left={8} />
            ) : status ? (
              <Badge
                color={statusColor}
                label={status}
                left={8}
                testId={`${testId}__statusBadge`}
              />
            ) : (
              ''
            )}
          </div>
          {subtitle && (
            <div className="Accordion__subtitle" data-testid={`${testId}__subtitle`}>
              {subtitle}
            </div>
          )}
        </div>
      </div>
      {badgeIcon && (
        <Badge color={badgeIcon.color} icon={badgeIcon.icon} testId={`${testId}__badgeIcon`} />
      )}
      {!!totalSteps && (
        <div className={styles.StepDetails}>
          {detail()}
          <AccordionRing
            currentStep={currentStep}
            disabled={disabled}
            size={44}
            strokeColor="#007BC1"
            strokeWidth={3}
            totalSteps={totalSteps}
          />
        </div>
      )}
    </div>
  );

  const body = (
    <div
      className={`Accordion__body ${bodyClassName} 
      ${goToNextStep ? 'Accordion__body--hasAction' : ''}`}
    >
      {children}
    </div>
  );

  const footer = () => {
    let actions = [];

    if (goToNextStep) {
      if (goToPrevStep) {
        actions.push(
          <Button key={1} label="Previous step" onClick={goToPrevStep} theme="secondary" />
        );
      }

      actions.push(
        <Button
          className="Accordion__buttonNext"
          disabled={buttonDisabled}
          key={2}
          onClick={goToNextStep}
          testId="Accordion__next"
          theme="primary"
        >
          {isCompletingStep && <Spinner className={styles.Spinner} />}
          <span className={isCompletingStep ? styles.ButtonLabelHidden : styles.ButtonLabel}>
            {buttonLabel}
          </span>
        </Button>
      );
      return <div className="Accordion__footer">{actions}</div>;
    }

    return null;
  };

  return (
    <AccordionCard
      body={body}
      footer={footer()}
      header={header}
      isActive={isActive}
      isAlwaysActive={isAlwaysActive}
      isLoading={isLoading}
      testId={testId}
      toggleAccordion={toggleAccordion}
      variant={`Accordion ${disabled ? 'Accordion--disabled' : ''} ${
        isActive ? 'Accordion--active' : ''
      } ${variant ? `Accordion--${variant}` : ''}`}
    />
  );
};
